import React, { useState, useCallback, useEffect } from "react"

import produce from "immer"
import saga from "./saga"
import { appActions } from "commons/reducer"
import { serviceActions } from "./reducer"
import { clearObject, composedComponent, removeKeyByFilter } from "utils/functions"
import { useForm } from "utils/hooks/useForm"
import { VALIDATIONS } from "utils/validations"
import { useHistory } from "react-router"

import {
  Container,
  withStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core"

import InfoService from "./components/InfoService"
import TableBudgets from "./components/TableBudgets"
import TableEvents from "./components/TableEvents"
import MenuToggleButton from "commons/components/MenuToggleButton"
import FormModalBudget from "./components/FormModal/Budget"
import FormModalEvent from "./components/FormModal/Event"
import FormModalBudgetDetails from "./components/FormModalDetails/Budget"
import FormModalEventDetails from "./components/FormModalDetails/Event"
import FormModalAcceptReject from "commons/components/AcceptRejectBudgetModal"
import Spinner from "commons/components/Spinner"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import styles from "./styles"
import Skeletop from "./Skeletop"
import Paths from "utils/paths"
import ContractHeader from "commons/components/ContractHeader"

const Service = (props) => {
  const {
    classes,
    actions,
    controls,
    match: {
      params: { id },
    },
  } = props

  const initState = {
    serviceId: id,
    cotizationNumber: "",
    sapNumber: "",
    total: "",
    commentary: "",
    date: "",
    eventTypeId: "",
    description: "",
    files: [],
    currentFiles: [],
    typeForm: "budget",
    detailId: "",
    typeFormDetail: "budget",
    budgetId: "",
    budgetObservation: "",
    budgetFiles: [],
  }

  const history = useHistory()
  const [state, setState] = useState(initState)

  // const [formEvent, setformEvent] = useState({});

  useEffect(() => {
    props.actions.getService({ id: id })
    props.actions.getEventTypes()
  }, [id])

  const confirmedBudgets = controls.budgets.filter((budget) => budget.save_status === "Confirmado")

  const onChangeText = useCallback(
    (id, value) => {
      setState(
        produce((draft) => {
          draft[id] = value
        })
      )
    },
    [controls.form.budgets, controls.form.event]
  )
  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    prettyErrors: true,
    validations: getValidations(),
  })

  const onChangeCustom = (event, newValue) => {
    setState({ ...state, [event.target.name]: newValue })
  }

  const onToggleModelBudget = () => {
    // props.actions.toggleSbudgetModal()
    // setState({ ...state, typeForm: "budget" })
    // clearErrors()
    history.push(`/service/${id}/createbudget`)
  }

  const onToggleUpdatedModelBudget = (id = "") => {
    props.actions.toggleSbudgetUpdateModal()
    setState(initState)
    clearErrors()

    if (typeof id === "number") {
      const item = props.controls.budgets.find((item) => item.id === Number(id))
      let auxItem = {}
      let budgetId = item.id
      let currentFiles = item.files
      auxItem = removeKeyByFilter(item, "id")
      auxItem = removeKeyByFilter(auxItem, "files")
      setState({ ...state, ...auxItem, currentFiles, budgetId, typeForm: "budget" })
    }
  }

  const onToggleModelEvent = () => {
    props.actions.toggleSeventModal()
    setState({ ...state, typeForm: "event" })
    clearErrors()
  }

  const onToggleModelBudgetDetail = (id = "") => {
    if (!controls.modal.budgetDetails.status && id) {
      props.actions.getBudget({ idService: controls.id, id: id })
    } else {
      props.actions.toggleSbudgetDetailsModal()
    }
    setState({ ...state, typeFormDetail: "budget" })
  }

  const onToggleModelEventDetail = (id = "") => {
    if (!controls.modal.eventDetails.status && id) {
      props.actions.getEvent({ idService: controls.id, id: id })
    } else {
      props.actions.toggleSeventDetailsModal()
    }
    setState({ ...state, typeFormDetail: "event" })
  }

  const onToggleModelBudgetAccept = (id = "") => {
    props.actions.toggleSbudgetAcceptRejectModal()
    setState({ ...state, budgetId: id, typeForm: "accept" })
  }

  const onToggleModelBudgetReject = (id = "") => {
    props.actions.toggleSbudgetAcceptRejectModal()
    setState({ ...state, budgetId: id, typeForm: "reject" })
  }

  const clearErrors = () => {
    clearObject(errors)
  }

  const onClickCreateBudget = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.createBudget(state)
      setState(initState)
    })
  }

  const onClickUpdateBudget = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.updateBudget(state)
      setState(initState)
    })
  }

  const onClickCreateEvent = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.createEvent(state)
      setState({ ...initState, typeForm: "event" })
    })
  }

  const onClickBudgetAccept = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.updateBudgetAccept(state)
      setState({ ...initState, typeForm: "accept" })
    })
  }

  const onClickBudgetReject = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.updateBudgetReject(state)
      setState({ ...initState, typeForm: "reject" })
    })
  }

  function getValidations() {
    switch (state.typeForm) {
      case "budget":
        return {
          date: [VALIDATIONS.REQUIRED],
          cotizationNumber: [VALIDATIONS.REQUIRED, VALIDATIONS.IS_NUMBER],
          total: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(3, 20)],
          commentary: [VALIDATIONS.REQUIRED],
        }
      case "event":
        return {
          date: [VALIDATIONS.REQUIRED],
          eventTypeId: [VALIDATIONS.REQUIRED],
          description: [VALIDATIONS.REQUIRED],
        }
      case "accept":
        return {
          budgetFiles: [VALIDATIONS.REQUIRED],
        }
      case "reject":
        return {}
    }
  }

  if (controls.loading && controls.getInfo) {
    return <Skeletop classes={classes} />
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid
        container
        spacing={4}
        justify="center"
        alignItems="center"
        className={classes.container}
      >
      <ContractHeader />
        <Grid item md={12} lg={12} xs={12}>
          <Typography variant="h5" component="a">
            Detalle del servicio #{controls.id}
          </Typography>
        </Grid>

        <InfoService
          titleCard1={"Agendamiento"}
          titleCard2={"Servicio"}
          details={controls.details}
          className={{ card: classes.card, p: classes.p, pEllipsis: classes.pEllipsis }}
        />

        <Grid item md={12} lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div">
                Presupuestos
              </Typography>
              <TableBudgets
                className={{ icon: classes.icon, p: classes.p }}
                data={confirmedBudgets}
                serviceStatus={controls.details.status}
                onModal={onToggleModelBudgetDetail}
                onModalEdit={onToggleUpdatedModelBudget}
                onModalBudgetAccept={onToggleModelBudgetAccept}
                onModalBudgetReject={onToggleModelBudgetReject}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12} lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div">
                Eventos
              </Typography>
              <TableEvents
                className={{ icon: classes.icon, p: classes.p }}
                data={controls.events}
                onModal={onToggleModelEventDetail}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <FormModalBudget
        submit={props.edit.budget ? onClickUpdateBudget : onClickCreateBudget}
        onChange={onChange}
        onChangeCustom={onChange}
        toggleForm={props.edit.budget ? onToggleUpdatedModelBudget : onToggleModelBudget}
        isOpen={controls.modal.budget.status}
        data={state}
        errors={errors}
        loading={controls.loading}
        style={classes.modal}
        classes={classes}
        edit={props.edit.budget}
      />

      <FormModalEvent
        submit={onClickCreateEvent}
        onChange={onChange}
        onChangeCustom={onChange}
        onChangeSwitch={onChangeCustom}
        toggleForm={onToggleModelEvent}
        isOpen={controls.modal.event.status}
        data={state}
        eventTypes={controls.eventTypes}
        errors={errors}
        loading={controls.loading}
        style={classes.modal}
        classes={classes}
      />

      <FormModalBudgetDetails
        toggleForm={onToggleModelBudgetDetail}
        isOpen={controls.modal.budgetDetails.status}
        data={controls.budgetDetails}
        loading={controls.loading}
        style={classes.modal}
      />

      <FormModalEventDetails
        toggleForm={onToggleModelEventDetail}
        isOpen={controls.modal.eventDetails.status}
        data={controls.eventDetails}
        loading={controls.loading}
        style={classes.modal}
      />

      <FormModalAcceptReject
        submit={!state.typeForm.localeCompare("accept") ? onClickBudgetAccept : onClickBudgetReject}
        onChange={onChange}
        onChangeCustom={onChange}
        toggleForm={
          !state.typeForm.localeCompare("accept")
            ? onToggleModelBudgetAccept
            : onToggleModelBudgetReject
        }
        isOpen={controls.modal.budgetAcceptReject.status}
        data={state}
        errors={errors}
        loading={controls.loading}
        style={classes.modal}
        className={classes}
        isModalAccept={!state.typeForm.localeCompare("accept")}
      />

      <MenuToggleButton
        className={{ fixedBtnAdd: classes.fixedBtnAdd }}
        onToggleModelBudget={onToggleModelBudget}
        onToggleModelEvent={onToggleModelEvent}
      />

      <Spinner show={controls.loading} text={"Cargando..."}></Spinner>
    </Container>
  )
}

export default composedComponent(Service, saga, {
  saga: "sagaService",
  middlewares: [withStyles(styles)],
  states: [
    "service.controls",
    "service.edit",
    "app.user",
    "app.errorMsg",
    "app.error",
    "app.successMsg",
    "app.success",
  ],
  actions: [serviceActions, appActions],
})
