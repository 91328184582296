import React, { useState, useCallback, useEffect } from "react"

import produce from "immer"
import saga from "./saga"
import { appActions } from "commons/reducer"
import { homeActions } from "./reducer"

import {
  clearObject,
  composedComponent,
  fCurrency,
  fNumber,
  isEmptyObject,
  removeKeyByFilter,
} from "utils/functions"
import { useForm } from "utils/hooks/useForm"
import { VALIDATIONS } from "utils/validations"

import {
  Container,
  withStyles,
  Grid,
  Typography,
  CardContent,
  Card,
  Button,
} from "@material-ui/core"

import Parrafo from "commons/components/Parrafo"
import TableServices from "./components/TableServices"
import FormModal from "./components/FormModal"
import ApprovatedFormModal from "./components/ApprovatedFormModal"
import RejectedFormModal from "./components/RejectedFormModal"
import DeleteModal from "commons/components/DeleteModal"
import FormModalAcceptReject from "commons/components/AcceptRejectBudgetModal"
import MenuButton from "commons/components/MenuButton"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import EXCEL_SVG from "utils/images/excel.svg"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"

import SocketContext from "commons/context/socket"
import AuthUserContext from "commons/context/user"

import styles from "./styles"
import "./styles.scss"
import Skeletop from "./Skeletop"
import { Link } from "react-router-dom"
import Paths from "utils/paths"
import ContractHeader from "commons/components/ContractHeader"

const Home = (props) => {
  const socket = React.useContext(SocketContext)
  const authUser = React.useContext(AuthUserContext)
  const { classes, actions, controls } = props
  const [state, setState] = useState({
    // Create-Service
    date: "",
    workshopName: "",
    vehicleName: "",
    mileage: "",
    description: "",
    maintenanceType: false,
    preventiveMaintenanceName: "",
    serviceType: "",
    // Accept-Reject-Service
    serviceId: "",
    approvedDate: "",
    rejectedObservation: "",
    // Accept-Reject-Budget
    budgetId: "",
    budgetObservation: "",
    budgetFiles: [],
    typeModal: "",
  })

  useEffect(() => {
    setState(controls.form)
  }, [controls.form])

  useEffect(() => {
    props.actions.getServices({})
    props.actions.getServiceWorkShop({})
    props.actions.getServiceVehicle({})
    props.actions.getServiceMaintenance({})
    props.actions.getServiceFilters({})
  }, [])

  const onChangeText = useCallback(
    (id, value) => {
      setState(
        produce((draft) => {
          draft[id] = value
        })
      )
    },
    [controls.services]
  )

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    prettyErrors: true,
    validations: getValidations(),
  })

  const onChangeCustom = (event, newValue) => {
    setState({ ...state, [event.target.name]: newValue })
  }

  const onToggleModel = () => {
    setState({ ...state, serviceId: "", typeModal: "form" })
    props.actions.toggleModal()
    clearErrors()
  }

  const onToggleUpdatedModel = (id = "") => {
    props.actions.toggleUpdateModal()
    // setState(initState)
    clearErrors()

    if (typeof id === "number") {
      const item = props.controls.services.find((item) => item.id === Number(id))
      let auxItem = {}
      let maintenanceType = null
      let preventiveMaintenanceName = ""
      let vehicleName = item?.vehicle?.plateNumber
      let workshopName = item?.workshop?.name
      if (!item?.type?.localeCompare("mantención")) {
        maintenanceType = !item?.maintenanceType?.localeCompare("correctiva") ? true : false
        if (!maintenanceType) {
          preventiveMaintenanceName = item?.preventiveMaintenance?.name
        }
      }
      auxItem = removeKeyByFilter(item, "budgets")
      auxItem = removeKeyByFilter(auxItem, "budgetFiles")
      auxItem = removeKeyByFilter(auxItem, "events")
      auxItem = removeKeyByFilter(auxItem, "vehicle")
      auxItem = removeKeyByFilter(auxItem, "workshop")
      setState({
        ...state,
        ...auxItem,
        workshopName,
        vehicleName,
        maintenanceType,
        preventiveMaintenanceName,
        serviceId: id,
        typeModal: "form",
        serviceType: item?.type,
      })
    }
  }

  const onToggleModelServiceReject = (id = "") => {
    props.actions.toggleRejectModal()
    setState({ ...state, serviceId: id, typeModal: "sReject" })
    clearErrors()
  }

  const onToggleModelServiceApprovated = (id = "") => {
    setState({ ...state, serviceId: id, typeModal: "sAccept" })
    props.actions.toggleApprovatedModal()
    clearErrors()
  }

  const onToggleModelBudgetAccept = (id = "", budgetId = "") => {
    props.actions.toggleSbudgetAcceptRejectModal()
    setState({
      ...state,
      serviceId: id,
      budgetId: budgetId,
      typeModal: "bAccept",
    })
  }

  const onToggleModelBudgetReject = (id = "", budgetId = "") => {
    props.actions.toggleSbudgetAcceptRejectModal()
    setState({
      ...state,
      serviceId: id,
      budgetId: budgetId,
      typeModal: "bReject",
    })
  }

  const onClickCreateService = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.createService(state)
    })
  }

  const onClickUpdateService = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.updateService(state)
    })
  }

  const onClickFilterService = (event, filters) => {
    event.preventDefault()
    props.actions.getServices(filters)
  }

  const onClickApprovatedService = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.approvatedService({ ...state, status: "Aprobado" })
    })
  }

  const onClickRejectService = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.rejectService({ ...state, status: "Rechazado" })
    })
  }

  const onClickBudgetAccept = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.updateBudgetAccept(state)
    })
  }

  const onClickBudgetReject = (event) => {
    event.preventDefault()
    onSubmit(async () => {
      props.actions.updateBudgetReject(state)
    })
  }

  const handleExcelExport = () => {
    props.actions.downloadServicesExport({ socketId: socket })
  }

  const clearErrors = () => {
    clearObject(errors)
  }

  function getValidations() {
    switch (state?.typeModal) {
      case "form":
        if (!state.serviceType.localeCompare("entrega de repuestos")) {
          return {
            date: [VALIDATIONS.REQUIRED],
            workshopName: [VALIDATIONS.REQUIRED],
            description: [VALIDATIONS.REQUIRED],
          }
        }
        if (!state.maintenanceType) {
          return {
            date: [VALIDATIONS.REQUIRED],
            workshopName: [VALIDATIONS.REQUIRED],
            vehicleName: [VALIDATIONS.REQUIRED],
            mileage: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(3, 20)],
            preventiveMaintenanceName: [VALIDATIONS.REQUIRED],
          }
        }
        return {
          date: [VALIDATIONS.REQUIRED],
          workshopName: [VALIDATIONS.REQUIRED],
          vehicleName: [VALIDATIONS.REQUIRED],
          mileage: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(3, 20)],
          description: [VALIDATIONS.REQUIRED],
        }
      case "sAccept":
        return {
          approvedDate: [VALIDATIONS.REQUIRED],
        }
      case "sReject":
        return {}
      case "bAccept":
        return {
          budgetFiles: [VALIDATIONS.REQUIRED],
        }
      case "bReject":
        return {}
      default:
        return {}
    }
  }

  useEffect(() => {
    if (!isEmptyObject(errors)) {
      clearErrors()
    }
  }, [state.maintenanceType, errors])

  if (controls.loading && !controls.services?.length) {
    return <Skeletop classes={classes} />
  }
  return (
    <Container maxWidth={false}>
      <ContractHeader />
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item md={12} lg={12} xs={12}>
          <Grid container md={12} lg={12} xs={12} spacing={1}>
            <Grid item md={4} lg={4} xs={4}>
              <Parrafo
                className={{ boxLineaCard: classes.boxLineaCard }}
                type="inline"
                title={"PRESUPUESTO TOTAL"}
                value={`${fCurrency.format(controls.not_executed_budget_num)}`}
              />
            </Grid>
            <Grid item md={4} lg={4} xs={4}>
              <Parrafo
                className={{ boxLineaCard: classes.boxLineaCard }}
                type="inline"
                title={"PRESUPUESTO FACTURADO"}
                value={`${fCurrency.format(controls.executed_budget_num)} (${fNumber.format(
                  controls.executed_budget_porcentual
                )}%)`}
              />
            </Grid>
            <Grid item md={4} lg={4} xs={4}>
              <Parrafo
                className={{ boxLineaCard: classes.boxLineaCard }}
                type="inline"
                title={"PRESUPUESTO DISPONIBLE"}
                value={`${fCurrency.format(controls.leftover_executed_budget_num)}`}
              />
            </Grid>
          </Grid>
          <Card>
            <CardContent>
              <div className="title-table flex-section flex-space_betwee">
                <Typography className={classes.title} variant="h3" component="h3">
                  Servicios
                </Typography>
                <Grid item>
                  {["Administrator"].includes(authUser?.rol?.name) && (
                    <Link to={Paths.CONTRACT_DOCUMENTS} className={classes.decorationNone}>
                      <Button
                        variant="contained"
                        color="default"
                        className={`${classes.button} ${classes.h55}`}
                        startIcon={<InsertDriveFileIcon color="primary" />}
                      >
                        Documentos
                      </Button>
                    </Link>
                  )}
                  <ButtonWithLoader
                    onClick={handleExcelExport}
                    color={"default"}
                    className={classes.button}
                    loading={controls.loading}
                    startIcon={<img src={EXCEL_SVG} alt="icon" className="export-excel" />}
                  >
                    exportar
                  </ButtonWithLoader>
                </Grid>
              </div>
              <TableServices
                className={{
                  icon: classes.icon,
                  p: classes.p,
                  boxLineaCard: classes.boxLineaCard,
                  containerFilter: classes.containerFilter,
                  leyendIconContainer: classes.leyendIconContainer,
                  leyendIcon: classes.leyendIcon,
                  leyendIconSpan: classes.leyendIconSpan,
                  button: classes.button,
                }}
                loading={controls.loading}
                onFilter={onClickFilterService}
                onModalEdit={onToggleUpdatedModel}
                onModalReject={onToggleModelServiceReject}
                onModalApprovated={onToggleModelServiceApprovated}
                onModalBudgetAccept={onToggleModelBudgetAccept}
                onModalBudgetReject={onToggleModelBudgetReject}
                data={controls.services}
                executed_budget_num={controls.executed_budget_num}
                executed_budget_porcentual={controls.executed_budget_porcentual}
                leftover_executed_budget_num={controls.leftover_executed_budget_num}
                helperFilters={controls.fields.filters}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <FormModal
        submit={props.edit.service ? onClickUpdateService : onClickCreateService}
        onChange={onChange}
        onChangeCustom={onChangeCustom}
        onChangeSwitch={onChangeCustom}
        toggleForm={props.edit.service ? onToggleUpdatedModel : onToggleModel}
        isOpen={controls.modal.status}
        data={state}
        errors={errors}
        loading={controls.loading}
        style={classes.modal}
        helperVehicles={controls.fields.vehicles}
        helperWorkshops={controls.fields.workshops}
        helperMaintenances={controls.fields.maintenances}
        className={classes}
        edit={props.edit.service}
      />

      {/* <DeleteModal */}
      <RejectedFormModal
        submit={onClickRejectService}
        onChange={onChange}
        onChangeCustom={onChange}
        onChangeSwitch={onChangeCustom}
        toggleForm={onToggleModelServiceReject}
        isOpen={controls.modal.reject.status}
        data={state}
        errors={errors}
        title={"Confirmar rechazo de servicio"}
        description="¿Está seguro que desea rechazar este servicio?"
        className={classes}
      />

      <ApprovatedFormModal
        submit={onClickApprovatedService}
        onChange={onChange}
        onChangeCustom={onChange}
        onChangeSwitch={onChangeCustom}
        toggleForm={onToggleModelServiceApprovated}
        isOpen={controls.modal.approvated.status}
        data={state}
        errors={errors}
        className={classes}
      />

      <FormModalAcceptReject
        submit={
          !state?.typeModal?.localeCompare("bAccept") ? onClickBudgetAccept : onClickBudgetReject
        }
        onChange={onChange}
        onChangeCustom={onChange}
        toggleForm={
          !state?.typeModal?.localeCompare("bAccept")
            ? onToggleModelBudgetAccept
            : onToggleModelBudgetReject
        }
        isOpen={controls.modal.budgetAcceptReject.status}
        data={state}
        errors={errors}
        loading={controls.loading}
        style={classes.modal}
        className={classes}
        isModalAccept={!state?.typeModal?.localeCompare("bAccept")}
      />

      <MenuButton className={classes.fab} handleToggle={onToggleModel} />
    </Container>
  )
}

export default composedComponent(Home, saga, {
  saga: "sagaHome",
  middlewares: [withStyles(styles)],
  states: [
    "home.controls",
    "home.edit",
    "app.user",
    "app.errorMsg",
    "app.error",
    "app.successMsg",
    "app.success",
  ],
  actions: [homeActions, appActions],
})
