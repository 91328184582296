import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import "./styles.css"
import { Box, Grid, Typography } from "@material-ui/core"

ChartJS.register(ArcElement, Tooltip, Legend)

const COLORS = [
  {
    facturado: "#7ED957",
    proceso: "#FF914D",
    saldo: "#77CDFD",
  },
  {
    facturado: "#4CB662",
    proceso: "#B49565",
    saldo: "#737373",
  },
  {
    facturado: "#1E6FDB",
    proceso: "#0097B2",
    saldo: "#FFDE59",
  },
]

const DoughnutChart = () => {
  const data = {
    labels: ["Facturado", "Procesado", "Saldo disponible"],
    datasets: [
      {
        data: [55, 24, 15],
        backgroundColor: ["#8DD25B", "#F5A05F", "#7CC8EB"],
        hoverBackgroundColor: ["#8DD25B", "#F5A05F", "#7CC8EB"],
      },
    ],
  }
  const options = {
    plugins: {
      legend: {
        display: false, // Oculta la leyenda por defecto
      },
      tooltip: {
        enabled: true, // Muestra los valores cuando pasas el mouse
      },
    },
    cutout: "70%", // Para hacer que el gráfico sea tipo "doughnut"
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <span
                  style={{
                    backgroundColor: "#8DD25B",
                    width: "24px",
                    height: "24px",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></span>
              </Grid>
              <Grid item>
                <Typography component="div">
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    Facturado:
                  </Box>
                </Typography>
                <Typography color="textSecondary" component="div">
                  <Box fontSize={14}>$ 1.287.371.293</Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <span
                  style={{
                    backgroundColor: "#8DD25B",
                    width: "24px",
                    height: "24px",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></span>
              </Grid>
              <Grid item>
                <Typography component="div">
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    Proceso:
                  </Box>
                </Typography>
                <Typography color="textSecondary" component="div">
                  <Box fontSize={14}>$ 1.287.371.293</Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <span
                  style={{
                    backgroundColor: "#8DD25B",
                    width: "24px",
                    height: "24px",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></span>
              </Grid>
              <Grid item>
                <Typography component="div">
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    Saldo disponible:
                  </Box>
                </Typography>
                <Typography color="textSecondary" component="div">
                  <Box fontSize={14}>$ 1.287.371.293</Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Doughnut data={data} options={options} />
      </Grid>
    </Grid>
  )
}

export default DoughnutChart
