import commonStyles from "utils/commonStyles"

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "56px",
    height: "56px",
    backgroundColor: "#4caf50",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  ...commonStyles,
})

export default styles
