import { put, spawn, takeLatest } from "redux-saga/effects";
import { CONTRACTS } from "../reducer";
import { apiSuccess, get } from "utils/api";
import { APP } from "commons/reducer";

function* getContracts() {
  yield takeLatest(CONTRACTS.GET_CONTRACTS, function* (action) {
    const response = yield get("api/v1/contracts")
    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response))
    } else {
      yield put(apiSuccess(CONTRACTS.GET_CONTRACTS_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getContracts)
}